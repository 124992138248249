import { EntityType, EntityUtils, SchemaEntityUtils } from "../..";
import { AuthoringStateMgr } from "./AuthoringStateMgr";
import { getCatalogEntities } from '../../utils/getCatalogEntities';

const FIND_ATTRIBUTES_RE: RegExp = /#(?<attribute>[-0-9a-z_.]+)#/gi;

export class ItemTemplatesMgr {
    private authoringMgr: AuthoringStateMgr;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
    }

    public async createItem(
        templateId: string,
        code: string,
        name?: string,
        groupRefs: string[] = []
    ) {
        const starterCatalogId = templateId.split(".")[0];
        const template = await this._getTemplate(templateId, code, name);
        const item = await this._generateItemFromTemplate(
            template,
            code,
            name,
            groupRefs,
            starterCatalogId
        );

        this._processTemplateDependencies(starterCatalogId);

        this.authoringMgr.entities.createEntity(
            EntityType.ITEMS,
            code,
            String(this.authoringMgr.state.currentCatalogVersion!.id),
            item
        );

        this.authoringMgr.notifyChange();

        return item;
    }

    private async _processTemplateDependencies(starterCatalogId: string) {
        const catalogId: string = String(this.authoringMgr.state.currentCatalogVersion!.id);
        const catalogEntities = await getCatalogEntities(EntityType.DEPENDENCIES, catalogId);
        const currentDependencies = this.authoringMgr.state.currentCatalogVersion?.catalogDependencies;
        const dependencies = await this.authoringMgr.dependencies.getCatalogDependencies(starterCatalogId);

        if (currentDependencies && dependencies) {
            Object.entries(dependencies).forEach(([refCode, dependency]) => {
                if(!currentDependencies[refCode]) {
                    this.authoringMgr.dependencies.createDependency(
                        catalogId,
                        refCode,
                        dependency,
                        catalogEntities[0]
                    );
                }
            });
        }
    }

    private async _getTemplate(
        templateId: string,
        code: string,
        name?: string
    ) {
        const defaultLocale =
            this.authoringMgr.state.currentCatalog?.localization.default ||
            "en-US";
        let template: ICatalogItemDef | undefined;
        if (templateId !== "blank") {
            template = (await EntityUtils.getCatalogEntity(
                EntityType.ITEMS,
                templateId,
                ""
            )) as ICatalogItemDef;
        }

        if (!template) {
            template = {
                code,
                names: {
                    main: {
                        [defaultLocale]: name || code,
                    },
                },
            } as unknown as ICatalogItemDef;
        }
        return template;
    }

    private async _generateItemFromTemplate(
        template: ICatalogItemDef,
        code: string,
        name?: string,
        groupRefs: string[] = [],
        starterCatalogId: string = ""
    ) {
        const defaultLocale = this.authoringMgr.state.currentCatalog?.localization.default || "en-US";
        const schema = await this.authoringMgr.schemas.fetchSchema(
            EntityType.ITEMS,
            template
        );
        const item: ICatalogItemDef = SchemaEntityUtils.fromEntityInstance(
            template,
            schema
        );

        item.code = code;
        item.groupRefs = groupRefs && groupRefs[0] === "uncategorized" ? [] : groupRefs;
        delete item.classification?.tags; 

        if (name) {
            (item.names.main as Record<string, string>)[defaultLocale] = name;
        }

        this._copyAttributes(item, starterCatalogId);
        this._copyFeatures(item, starterCatalogId);
        this._copyMaterials(item, starterCatalogId);
        this._copyRestrictions(item, starterCatalogId);

        return item;
    }

    private async _copyRestrictions(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        await this.authoringMgr.schemas.fetchSchema(EntityType.RESTRICTIONS);
        const restrictions = item.restrictionRefs || [];
        restrictions.forEach((restrictionRef) => {
            if (!restrictionRef.includes(":")) {
                this.authoringMgr.restrictions.copyRestriction(
                    starterCatalogId,
                    restrictionRef
                );
            }
        });
    }

    private async _copyMaterials(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        await this.authoringMgr.schemas.fetchSchema(EntityType.MATERIALS);
        const materials =
            item.geometries?.main?.layers?.map((l) => l.materialRef) || [];

        materials.forEach((materialRef) => {
            if (!materialRef.includes(":")) {
                this.authoringMgr.materials.copyMaterial(
                    starterCatalogId,
                    materialRef
                );
            }
        });
    }

    private async _copyFeatures(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        await this.authoringMgr.schemas.fetchSchema(EntityType.FEATURES);
        const features = item.featureRefs || [];
        features.forEach((featureRef) => {
            if (!featureRef.featureRef.includes(":")) {
                this.authoringMgr.features.copyFeature(
                    starterCatalogId,
                    featureRef.featureRef
                );
            }
        });
    }

    private async _copyAttributes(
        item: ICatalogItemDef,
        starterCatalogId: string
    ) {
        // avoid fetching the schema for each attribute
        await this.authoringMgr.schemas.fetchSchema(EntityType.ATTRIBUTES);
        await CiCAPI.authoring.getCatalogAttributeDefs(starterCatalogId).then((resp: { success?: unknown; result?: ICatalogAttributeDef[] } ) => 
        
        {
            if (resp.success && resp.result) {
                resp.result.forEach((attr) => { 
                    
                    const attrsToCopy = new Set<string>();
                    
                    attrsToCopy.add(attr.code);                        

                    attrsToCopy.forEach((attrToCopy) => {
                        this.authoringMgr.attributes.copyAttribute(
                            starterCatalogId,
                            attrToCopy
                        );
                    });
                    
                });
            }
        });
    }
}
